import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Grid1fr1fr } from 'src/components/grid-layout'
import { LoginHelp } from 'src/components/login-help'
import { emailRegex } from 'src/constants/regex-patterns'
import { useLoader } from 'src/domain/hooks/loader-context'
import { DashboardRoute } from 'src/domain/routes'
import { apiClient } from 'src/utilities/api-clients/api-client'
import styled from 'styled-components'

import { Spacing } from '@igs/react'
import { Body1, Button, Card, Headline4, Input } from '@igs/react-styled-components'

const StyledButton = styled(Button)`
    width: 100%;
    margin-top: ${Spacing.medium};
`

const StyledInput = styled(Input)`
    margin-bottom: ${Spacing.medium};
`

const PasswordInput = styled(StyledInput)`
    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }
`

export const LoginPage = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm<LoginFormFields>()
    const history = useHistory()
    const { setIsLoading } = useLoader()
    const onSubmit = async (data: LoginFormFields) => {
        setIsLoading(true)
        const response = await submitLogin(data)
        setIsLoading(false)
        if (response) {
            reset()
        }
    }

    const submitLogin = async ({ email, password }: LoginFormFields): Promise<boolean> => {
        try {
            await apiClient.login(email, password, {
                overrideNonUserPresentableErrors: 'Login Unsuccessful',
            })
            history.push(DashboardRoute)
            return true
        } catch (e) {
            const formError = {
                type: 'server',
                message: 'Username or password is incorrect',
            }
            setError('email', formError)
            setError('password', formError)
            return false
        }
    }

    React.useEffect(() => {
        if (apiClient.hasToken()) {
            history.push(DashboardRoute)
        }
    })

    return (
        <Grid1fr1fr>
            <Card>
                <Headline4>Sign In</Headline4>
                <Body1>
                    This website is for federal, state, local and private customer assistance agencies. WebPledge is a fast and secure way to look up accounts
                    and make pledges on behalf of SCANA Energy customers.
                </Body1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div></div>
                    <StyledInput
                        {...register('email', {
                            required: 'Required',
                            pattern: { value: emailRegex, message: 'Invalid Email' },
                        })}
                        dataTestId="login-email"
                        label="Username"
                        type="email"
                        autoComplete="email"
                        error={errors.email?.message}
                        hideHelperText={!errors.email}
                    />
                    <PasswordInput
                        {...register('password', { required: 'Required' })}
                        dataTestId="login-password"
                        label="Password"
                        type="password"
                        autoComplete="password"
                        error={errors.password?.message}
                        hideHelperText={!errors.password}
                    />
                    <StyledButton dataTestId="login-submit" type="submit">
                        Sign In
                    </StyledButton>
                </form>
            </Card>
            <LoginHelp />
        </Grid1fr1fr>
    )
}

interface LoginFormFields {
    readonly email: string
    readonly password: string
}
