import { PledgeType } from 'src/constants/pledge-type'

export interface Pledge {
  pledgeType: PledgeType
  amount: number
  name?: string
}

export enum PledgeAmount {
  Medium = 560,
  High = 660,
}
