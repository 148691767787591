import { Body1, Card } from '@igs/react-styled-components'

import { dhrAssistanceEmail, dhrAssistanceFaxNumber, dhrAssistancePhoneNumber } from 'src/constants/dhr-assistance-contact-info'

export const FormSelectionHelpCard = () => {
    return (
        <Card>
            <Body1>
                This page is intended to submit pledges for active customers or to obtain account information to assist your client.{' '}
                <strong>If your customer has a pending disconnection or gas is off, please contact the DHR Assistance Line.</strong>
            </Body1>
            <Body1>
                <strong>Phone:</strong> <a href={`tel:${dhrAssistancePhoneNumber}`}>{dhrAssistancePhoneNumber}</a>
            </Body1>
            <Body1>
                <strong>Email:</strong> <a href={`mailto:${dhrAssistanceEmail}`}>{dhrAssistanceEmail}</a>
            </Body1>
            <Body1>
                <strong>Fax:</strong> <a href={`tel:${dhrAssistanceFaxNumber}`}>{dhrAssistanceFaxNumber}</a>
            </Body1>
        </Card>
    )
}
