import { Route, Switch } from 'react-router-dom'

import { ProtectedRoute } from 'src/domain/authentication/protected-route'
import * as Routes from 'src/domain/routes'
import { DashboardPage } from 'src/pages/dashboard'
import { LoginPage } from 'src/pages/login'
import { PasswordReset } from 'src/pages/password-reset'
import { PledgeSupportPage } from 'src/pages/pledge-support'
import { RequestAdditionalInfo } from 'src/pages/request-add-info'

export const Routing = () => {
    return (
        <Switch>
            <Route exact path={Routes.HomeRoute}>
                <LoginPage />
            </Route>
            <ProtectedRoute path={Routes.DashboardRoute}>
                <DashboardPage />
            </ProtectedRoute>
            <ProtectedRoute path={Routes.PledgeRoute}>
                <PledgeSupportPage />
            </ProtectedRoute>
            <ProtectedRoute path={Routes.RequestInfoRoute}>
                <RequestAdditionalInfo />
            </ProtectedRoute>
            <Route path={Routes.PasswordResetRoute}>
                <PasswordReset />
            </Route>
        </Switch>
    )
}
