import { BaseTheme, lightTheme } from '@igs/react-styled-components'

const frameworkTheme = lightTheme()
export const defaultTheme = (): WebPledgeTheme => ({
    ...frameworkTheme,
    border: '4px solid #e9d73a',
    footerBackgroundColor: '#eeeeee',
    bodyBackgroundColor: '#ffffff',
})

export interface WebPledgeTheme extends BaseTheme {
    readonly border: string
    readonly footerBackgroundColor: string
    readonly bodyBackgroundColor: string
}
