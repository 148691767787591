import styled, { css } from 'styled-components'

import { Grid1fr } from './grid-layout'

const errorCss = css`
    border: 1px ${p => p.theme.negative.color} solid;
    border-radius: 5px;
`

export const ErrorWrapper = styled(Grid1fr)<ErrorWrapperProps>`
    ${p => !p.isValid && errorCss}
`

export const ErrorMessage = styled.div`
    color: ${p => p.theme.negative.text};
    font-size: 0.8rem;
`

interface ErrorWrapperProps {
    readonly isValid: boolean
}
