import { useFormContext } from 'react-hook-form'
import { Input, Radio, RadioGroup, RadioGroupTitle } from 'src/components/web-framework-inputs'
import { PledgeType } from 'src/constants/pledge-type'
import { dollarAmountRegex } from 'src/constants/regex-patterns'
import { Pledge, PledgeAmount } from 'src/models/pledge'
import styled from 'styled-components'

import { ErrorMessage, ErrorWrapper } from '../errors'

const PledgeAmountGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`

export const PledgeAmountForm = ({ formName }: Props) => {
    const methods = useFormContext<PledgeAmountFields>()

    const {
        register,
        watch,
        formState: { errors },
    } = methods
    const pledgeTypeSelection = watch('pledge.pledgeType')

    return (
        <PledgeAmountGrid>
            <ErrorWrapper isValid={!errors.pledge?.pledgeType}>
                <RadioGroup>
                    <RadioGroupTitle>Pledge Type:</RadioGroupTitle>
                    <Radio
                        {...register('pledge.pledgeType', { required: 'Required' })}
                        dataTestId={`${formName}-pledge-type-${PledgeType.LiHeap}`}
                        label={PledgeType.LiHeap}
                        value={PledgeType.LiHeap}
                    />
                    <Radio
                        {...register('pledge.pledgeType', { required: 'Required' })}
                        dataTestId={`${formName}-pledge-type-${PledgeType.Crisis}`}
                        label={PledgeType.Crisis}
                        value={PledgeType.Crisis}
                    />
                    <Radio
                        {...register('pledge.pledgeType', { required: 'Required' })}
                        dataTestId={`${formName}-pledge-type-${PledgeType.Other}`}
                        label={PledgeType.Other}
                        value={PledgeType.Other}
                    />
                </RadioGroup>
                {errors.pledge?.pledgeType && <ErrorMessage>{errors.pledge?.pledgeType?.message}</ErrorMessage>}
            </ErrorWrapper>
            {pledgeTypeSelection && (
                <ErrorWrapper isValid={!errors.pledge?.amount}>
                    <RadioGroup>
                        <RadioGroupTitle>Pledge Amount:</RadioGroupTitle>
                        {(pledgeTypeSelection === PledgeType.Crisis || pledgeTypeSelection === PledgeType.LiHeap) && (
                            <>
                            <Radio
                                {...register('pledge.amount', {
                                    required: 'Required',
                                    shouldUnregister: true,
                                })}
                                dataTestId={`${formName}-pledge-amount-${PledgeAmount.Medium}`}
                                label={`$${PledgeAmount.Medium}`}
                                value={PledgeAmount.Medium}
                            />
                             <Radio
                                {...register('pledge.amount', {
                                    required: 'Required',
                                    shouldUnregister: true,
                                })}
                                dataTestId={`${formName}-pledge-amount-${PledgeAmount.High}`}
                                label={`$${PledgeAmount.High}`}
                                value={PledgeAmount.High}
                            />
                                {errors.pledge?.amount && <ErrorMessage>{errors.pledge?.amount?.message}</ErrorMessage>}
                            </>
                        )}
                        {pledgeTypeSelection === PledgeType.Other && (
                            <div>
                                <Input
                                    {...register('pledge.amount', {
                                        required: 'Required',
                                        shouldUnregister: true,
                                        pattern: {
                                            value: dollarAmountRegex,
                                            message: 'Dollar amount required',
                                        },
                                    })}
                                    dataTestId={`${formName}-pledge-amount-other`}
                                    label="Custom Amount"
                                    error={errors.pledge?.amount?.message}
                                    leadingIcon="attach_money"
                                    showRequired={true}
                                    hideHelperText={!errors.pledge?.amount}
                                />
                            </div>
                        )}
                    </RadioGroup>
                </ErrorWrapper>
            )}
        </PledgeAmountGrid>
    )
}

interface PledgeAmountFields {
    pledge: Pledge
}
interface Props {
    readonly formName: string
}
