import styled from 'styled-components'

import { Body1, Headline5, Headline4 } from '@igs/react-styled-components'
import { Spacing } from '@igs/react'

import { dhrAssistancePhoneNumber } from 'src/constants/dhr-assistance-contact-info'
import { communityActionAgenciesLink } from 'src/constants/external-links'

const HelpQuestion = styled(Headline5)`
    margin-bottom: 0px;
`
const LoginHelpContainer = styled.div`
    padding: ${Spacing.medium};
`
const StyledHeadline4 = styled(Headline4)`
    margin-top: 0px;
`

export const LoginHelp = () => {
    return (
        <LoginHelpContainer>
            <StyledHeadline4>Need Help?</StyledHeadline4>
            <div>
                <HelpQuestion>How can I request financial aid from a Customer Assistance Agency?</HelpQuestion>
                <Body1>
                    To request assistance, contact your local <a href={communityActionAgenciesLink}>community action agency</a>
                </Body1>
            </div>
            <div>
                <HelpQuestion>How does my agency sign up?</HelpQuestion>
                <Body1>
                    To register your agency, call our SCANA Energy DHR Assistance line at{' '}
                    <a href={`tel:${dhrAssistancePhoneNumber}`}>{dhrAssistancePhoneNumber}</a> to receive a username and password
                </Body1>
            </div>
            <div>
                <HelpQuestion>Lost your username and/or password?</HelpQuestion>
                <Body1>
                    For an agent's username or password, contact your supervisor or primary contact at your agency for assistance. For the primary contact's
                    username or password, call <a href={`tel:${dhrAssistancePhoneNumber}`}>{dhrAssistancePhoneNumber}</a> for assistance.
                </Body1>
            </div>
        </LoginHelpContainer>
    )
}
