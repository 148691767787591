import styled from 'styled-components'

import { Spacing } from '@igs/react'
import { Input as WfInput, Radio as WfRadio, Select as WfSelect, SelectOption as WfSelectOption } from '@igs/react-styled-components'

export const Input = styled(WfInput)`
    height: auto;
    align-self: flex-start;
    margin-bottom: ${({ hideHelperText }) => (!hideHelperText ? `-${Spacing.medium}` : 0)};
`

export const Select = styled(WfSelect)`
    select {
        height: auto;
        align-self: flex-start;
    }

    option {
        height: auto;
        align-self: flex-start;
    }
`
export const SelectOption = styled(WfSelectOption)`
    height: auto;
    align-self: flex-start;
`

export const RadioGroup = styled.div`
>label{
    margin-left:10px;
}
`

export const RadioGroupTitle = styled.h3`
font-size: .9rem;
margin-bottom: 10px;
`


export const Radio = styled(WfRadio)`
   ~ p {
        font-size: 1rem;
       
        padding:5px 0;
        margin:0;
    }
`
