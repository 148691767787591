import React from 'react'
import ReactDOM from 'react-dom'

import * as FullStory from '@fullstory/browser'

import App from './App'

FullStory.init({ orgId: '14PPND' })

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
)
