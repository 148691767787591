import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { HtmlType } from 'src/constants/html-types'
import { phoneNumberRegex } from 'src/constants/regex-patterns'
import { useUserState } from 'src/domain/authentication/hooks/use-user'
import { Agency } from 'src/models/agency'

import { emailAddressRegex } from '@igs/react'
import { Input } from '@igs/react-styled-components'

import { Grid1fr1fr } from '../grid-layout'

const contactValidation = (value: string) => {
  if (!phoneNumberRegex.test(value) && !emailAddressRegex.test(value)) {
    return 'Phone Number or Email Required'
  }
}

export const AgencyForm = ({ formName }: Props) => {
  const methods = useFormContext<AgencyFormFields>()
  const userState = useUserState()
  const {
    register,
    formState: { errors },
  } = methods

  return (
    <Grid1fr1fr>
      <Input
        {...register('agency.AgencyName', { required: 'Required' })}
        dataTestId={`${formName}-agency-name`}
        defaultValue={userState.agency?.AgencyName}
        label='Agency Name'
        error={errors.agency?.AgencyName?.message}
        showRequired={true}
        hideHelperText={!errors.agency?.AgencyName}
        type={HtmlType.Text}
      />
      <Input
        {...register('agency.RepresentativeName', { required: 'Required' })}
        dataTestId={`${formName}-representative-name`}
        defaultValue={userState.agency?.RepresentativeName}
        label='Representative Name'
        error={errors.agency?.RepresentativeName?.message}
        showRequired={true}
        hideHelperText={!errors.agency?.RepresentativeName}
        type={HtmlType.Text}
      />
      <Input
        {...register('agency.PhoneNumber', {
          required: 'Required',
          pattern: { value: phoneNumberRegex, message: 'Invalid Phonenumber' },
        })}
        dataTestId={`${formName}-phone-number`}
        defaultValue={userState.agency?.PhoneNumber}
        label='Phone Number'
        error={errors.agency?.PhoneNumber?.message}
        showRequired={true}
        hideHelperText={!errors.agency?.PhoneNumber}
        type={HtmlType.PhoneNumber}
      />
      <Input
        {...register('agency.ResponseContact', {
          required: 'Required',
          validate: (value) => contactValidation(value),
        })}
        dataTestId={`${formName}-request-confirmation-contact`}
        defaultValue={userState.agency?.ResponseContact}
        label='Response Contact (Email or Fax)'
        error={errors.agency?.ResponseContact?.message}
        showRequired={true}
        hideHelperText={!errors.agency?.ResponseContact}
        type={HtmlType.Text}
      />
    </Grid1fr1fr>
  )
}
interface AgencyFormFields {
  agency: Agency
}

interface Props {
  readonly formName: string
}
