/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useEffect } from 'react'
import { Agency } from 'src/models/agency'
import { getFromStorage, putInStorage } from 'src/utilities/storage-utilities'

import { userActions, userInitialState, userReducer } from './user-reducer'

export const userStorageKey = 'userStorageKey'
interface UserContextState {
    agency: Agency
}

interface UserContextDispatch {
    readonly setAgency: (agency: Agency) => void
}

const UserContextState = React.createContext<UserContextState | undefined>(undefined)
const UserContextDispatch = React.createContext<UserContextDispatch | undefined>(undefined)

export const useUserState = (): UserContextState => {
    const context = React.useContext(UserContextState)
    if (!context) {
        throw Error('Must use useUserState inside of UserContextProvider')
    }
    return context
}

export const useUserDispatch = ():UserContextDispatch => {
    const context = React.useContext(UserContextDispatch)
    if(!context){
        throw Error('Must use useUserDispatch inside of UserContextProvider')
    }
    return context
}

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
    
    const initialState:  UserContextState =  getFromStorage(userStorageKey) ?? userInitialState

    const [state, dispatch] = React.useReducer(userReducer, initialState)

    useEffect(() => {putInStorage(state,userStorageKey)},[state])

    const dispatchValue = {
        setAgency: (model?: Agency) => dispatch(userActions.setAgency(model)),
    }

    return (
        <UserContextState.Provider value={state}>
            <UserContextDispatch.Provider value={dispatchValue}>{children}</UserContextDispatch.Provider>
        </UserContextState.Provider>
    )
}

interface UserProviderProps {
    readonly children: React.ReactNode
}
