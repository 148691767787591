import { v4 as uuidv4 } from 'uuid'

export const generateUuid = (): string => uuidv4()

const cookiesEnabled = (): boolean => navigator.cookieEnabled

const getStorage = (useLocalStorage: boolean): Storage | undefined => {
  if (!cookiesEnabled()) {
    return undefined
  }
  return useLocalStorage ? localStorage : sessionStorage
}

export const putInStorage = <T>(
  data: T,
  key?: string,
  useLocalStorage = false
) => {
  const storage = getStorage(useLocalStorage)
  if (!storage) {
    return
  }

  key = key || generateUuid()
  storage.setItem(key, JSON.stringify(data))
  return key
}

export const getFromStorage = <T>(
  key: string,
  useLocalStorage = false
): T | undefined => {
  const storage = getStorage(useLocalStorage)
  if (!storage) {
    return
  }
  const result = storage.getItem(key)
  if (result) {
    return parseResult<T>(key, result)
  } else {
    return undefined
  }
}

const parseResult = <T>(key: string, result: string): T | undefined => {
  try {
    return JSON.parse(result) as T
  } catch (e) {
    try {
      return JSON.parse(JSON.stringify(result))
    } catch (ex) {
      clearFromStorage(key)
      return undefined
    }
  }
}

export const clearFromStorage = (key: string, useLocalStorage = false) => {
  const storage = getStorage(useLocalStorage)
  if (!storage) {
    return
  }
  storage.removeItem(key)
}
