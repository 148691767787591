export interface WebPledgeError extends Error {
    readonly message: string
    readonly isMessageUserPresentable: boolean
    readonly errorCode: WebPledgeErrorCode
}

export enum WebPledgeErrorCode {
    userNotFound = 'USER_NOT_FOUND',
    unknownServerError = 'UNKNOWN_SERVER_ERROR',
    unknownClientError = 'UNKNOWN_CLIENT_ERROR',
    clientOverride = 'CLIENT_OVERRIDE'
}

export const webPledgeError = (
    message: string,
    isMessageUserPresentable = false,
    errorCode: WebPledgeErrorCode = WebPledgeErrorCode.unknownClientError,
    error?: Error
): WebPledgeError => ({
    message,
    isMessageUserPresentable,
    errorCode,
    name: error ? error.name : errorCode
})