/* eslint-disable @typescript-eslint/no-redeclare */
import * as React from 'react'

interface LoaderContext {
    isLoading: boolean
    readonly setIsLoading: (isLoading: boolean) => void
}

const LoaderContext = React.createContext<LoaderContext | undefined>(undefined)

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const initialState: LoaderContext = {
        isLoading,
        setIsLoading,
    }

    return <LoaderContext.Provider value={initialState}>{children}</LoaderContext.Provider>
}

export const useLoader = (): LoaderContext => {
    const context = React.useContext(LoaderContext)
    if (!context) {
        throw Error('Must use useLoader inside of LoaderProvider')
    }
    return context
}

interface LoaderProviderProps {
    readonly children: React.ReactNode
}
