import styled, { css } from 'styled-components'
import { Spacing } from '@igs/react'
import { Breakpoint } from 'src/domain/styles/breakpoints'

const commonGridCss = css`
    display: grid;
    grid-row-gap: ${Spacing.large};
    grid-column-gap: ${Spacing.extraLarge};
    background: ${props => props.theme.background};
    width: 100%;
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
    }
`

export const Grid1fr = styled.div`
    grid-template-columns: 1fr;
    ${commonGridCss}
`

export const Grid1fr1fr = styled.div`
    grid-template-columns: 1fr 1fr;
    ${commonGridCss}
`
export const Grid1fr1fr1fr = styled.div`
    grid-template-columns: 1fr 1fr 1fr;
    ${commonGridCss}
`
