import { useHistory } from 'react-router-dom'
import { HomeRoute } from 'src/domain/routes'
import { apiClient } from 'src/utilities/api-clients/api-client'
import styled from 'styled-components'

import { Button } from '@igs/react-styled-components'

const StyledButton = styled(Button)`
    width: min-content;
    white-space: nowrap;
    height: min-content;
    align-self: center;
`

export const LogoutButton = () => {
    const history = useHistory()

    const handleLogout = async () => {
        await apiClient.logout()
        history.push(HomeRoute)
    }

    return (
        <StyledButton dataTestId={'logout-button'} onClick={handleLogout}>
            Sign Out
        </StyledButton>
    )
}
