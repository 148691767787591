import * as React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { Spacing } from '@igs/react'
import { Button, Headline3 } from '@igs/react-styled-components'

import { Input } from 'src/components/web-framework-inputs'
import { pledgeApiClient } from 'src/utilities/api-clients/pledge-api-client'
import { useState } from 'react'
import { ErrorMessage } from 'src/components/errors'
import { useLoader } from 'src/domain/hooks/loader-context'
import { useHistory } from 'react-router-dom'
import { HomeRoute } from 'src/domain/routes'

const StyledButton = styled(Button)`
    margin-top: ${Spacing.medium};
`

const StyledForm = styled.form`
    display: grid;
    grid-gap: ${Spacing.large};
`

const StyledInput = styled(Input)`
    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }
`

export const PasswordReset = () => {
    const { setIsLoading } = useLoader()
    const history = useHistory()
    const search = window.location.search
    const params = new URLSearchParams(search)
    const token = params.get('token') || ''

    if (!token) {
        history.push(HomeRoute)
    }
    const [userName, setUserName] = useState<string>()
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<PasswordResetFormFields>()

    const [submissionError, setSubmissionError] = useState<boolean>(false)

    const passwordWatch = watch('password')

    const onSubmit = async (data: PasswordResetFormFields) => {
        try {
            setSubmissionError(false)
            setIsLoading(true)
            const resetResponse = await pledgeApiClient.resetPassword({ password: data.password, resetKey: token })
            setIsLoading(false)
            resetResponse?.successful ? history.push(HomeRoute) : setSubmissionError(true)
        } catch (e) {
            setSubmissionError(true)
        }
    }

    React.useEffect(() => {
        const getUserName = async () => {
            setIsLoading(true)
            const response = await pledgeApiClient.getUserNameFromResetKey(token)
            if (response) {
                setUserName(response.username)
            } else {
                history.push(HomeRoute)
            }
            setIsLoading(false)
        }
        getUserName()
    }, [token, setIsLoading, history])

    return (
        <>
            <Headline3>Register</Headline3>
            {submissionError && <ErrorMessage>Unable to reset password, check your username and try again.</ErrorMessage>}
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <p>
                    <strong>Username: </strong>
                    {userName}
                </p>
                <StyledInput
                    {...register('password', { required: 'Required' })}
                    dataTestId="password"
                    label="Password"
                    type="password"
                    minLength={5}
                    autoComplete="password"
                    error={errors.password?.message}
                />
                <StyledInput
                    {...register('confirmPassword', { required: 'Required', validate: value => value === passwordWatch || 'Passwords do not match.' })}
                    dataTestId="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    autoComplete="password"
                    error={errors.confirmPassword?.message}
                />
                <StyledButton dataTestId="password-reset-submit" type="submit">
                    Submit
                </StyledButton>
            </StyledForm>
        </>
    )
}

interface PasswordResetFormFields {
    readonly username: string
    readonly password: string
    readonly confirmPassword: string
}
