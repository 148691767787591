import { PasswordResetRequest } from 'src/models/requests/password-reset-request'
import { PasswordResetResponse } from 'src/models/responses/password-reset-response'
import { PledgeRequest } from 'src/models/requests/pledge-request'
import { PledgeResponse } from 'src/models/responses/pledge-response'
import { UsernameResponse } from 'src/models/responses/username-response'

import { apiClient } from './api-client'

class PledgeApiClient {
    public readonly resetPassword = async (request: PasswordResetRequest) => {
        return await apiClient.post<PasswordResetResponse>(`${apiClient.apiBaseUrl}/WebPledge/ResetPassword`, request)
    }
    public readonly getUserNameFromResetKey = async (key: string) => {
        return await apiClient.get<UsernameResponse>(`${apiClient.apiBaseUrl}/WebPledge/GetUserNameFromResetKey?key=${key}`)
    }
    public readonly pledgeAssistance = async (request: PledgeRequest) => {
        return await apiClient.post<PledgeResponse>(`${apiClient.apiBaseUrl}/WebPledge/Pledge`, request)
    }
    public readonly requestInformation = async (request: PledgeRequest) => {
        await apiClient.post<PledgeRequest>(`${apiClient.apiBaseUrl}/WebPledge/Info`, request)
    }
}

export const pledgeApiClient = new PledgeApiClient()
