import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { InformationRequest } from 'src/models/requests/information-request'

import { Checkbox, Textarea } from '@igs/react-styled-components'

import { ErrorMessage, ErrorWrapper } from '../errors'
import { Grid1fr1fr1fr } from '../grid-layout'

const TEXT_AREA_MAX_CHAR_LENGTH = 750

export const InfoRequestForm = ({ formName, isRequired }: Props) => {
    const methods = useFormContext<InfoRequestFields>()
    const {
        register,
        getValues,
        formState: { errors },
        setError,
        clearErrors,
    } = methods

    const validateInfoRequest = () => {
        if (!isRequired) {
            clearErrors('informationRequest')
            return true
        }

        const checkBoxValues = getValues([
            'informationRequest.RequestCurrentBill',
            'informationRequest.RequestDisconnectNotice',
            'informationRequest.RequestWelcomeLetter',
            'informationRequest.RequestDepositRequiredLetter',
            'informationRequest.AdditionalInformationRequest',
        ])

        if (checkBoxValues.some(c => c)) {
            clearErrors('informationRequest')
            return true
        } else {
            setError('informationRequest', {
                type: 'required',
                message: 'Please select the information you are requesting or provide additional details.',
            })
            return false
        }
    }

    return (
        <ErrorWrapper isValid={!errors.informationRequest}>
            <Grid1fr1fr1fr>
                <Checkbox
                    {...register('informationRequest.RequestCurrentBill', {
                        validate: () => validateInfoRequest(),
                    })}
                    label="Current Bill"
                    dataTestId={`${formName}-current-bill`}
                />
                <Checkbox
                    {...register('informationRequest.RequestDisconnectNotice', {
                        validate: () => validateInfoRequest(),
                    })}
                    label="Disconnect Notice"
                    dataTestId={`${formName}-disconnect-notice`}
                />
                <Checkbox
                    {...register('informationRequest.RequestWelcomeLetter', {
                        validate: () => validateInfoRequest(),
                    })}
                    label="Welcome Letter"
                    dataTestId={`${formName}-welcome-letter`}
                />
                <Checkbox
                    {...register('informationRequest.RequestDepositRequiredLetter', {
                        validate: () => validateInfoRequest(),
                    })}
                    label="Deposit Required Letter"
                    dataTestId={`${formName}-deposit-required-letter`}
                />
            </Grid1fr1fr1fr>
            <Textarea
                {...register('informationRequest.AdditionalInformationRequest', {
                    validate: () => validateInfoRequest(),
                })}
                label="Any other requests not listed above or additional information needed"
                dataTestId={`${formName}-additional-information-text-box`}
                maxLength={TEXT_AREA_MAX_CHAR_LENGTH}
                hideHelperText={true}
            />
            {errors.informationRequest?.type === 'required' && <ErrorMessage>{errors.informationRequest.message}</ErrorMessage>}
        </ErrorWrapper>
    )
}

interface Props {
    readonly formName: string
    readonly isRequired: boolean
}

interface InfoRequestFields {
    informationRequest: InformationRequest
}
