import * as React from 'react'
import styled, { ThemeProps } from 'styled-components'
import { Spacing } from '@igs/react'

import { WebPledgeTheme } from './theme-wrapper'

const FooterContent = styled.div`
    text-align: center;
    text-justify: center;
`

const FooterContainer = styled.div`
    background-color: ${({ theme }: ThemeProps<WebPledgeTheme>): string => theme.footerBackgroundColor};
    border-top: ${({ theme }: ThemeProps<WebPledgeTheme>): string => theme.border};
    padding-top: ${Spacing.large};
    padding-bottom: ${Spacing.huge};
    margin-top: ${Spacing.huge};
    overflow: hidden;
`

export const Footer = () => (
    <FooterContainer>
        <FooterContent>
            SCANA Corporation &copy; 2000-
            {new Date().getFullYear()}
        </FooterContent>
    </FooterContainer>
)
