import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { AgencyForm } from 'src/components/forms/agency-form'
import { FormControlButtons } from 'src/components/forms/control-buttons'
import { CustomerInformationForm } from 'src/components/forms/customer-information-form'
import { FormSectionTitle, StyledForm } from 'src/components/forms/form'
import { InfoRequestForm } from 'src/components/forms/info-request-form'
import { PledgeAmountForm } from 'src/components/forms/pledge-amount-form'
import { useUserDispatch } from 'src/domain/authentication/hooks/use-user'
import { useLoader } from 'src/domain/hooks/loader-context'
import { DashboardRoute } from 'src/domain/routes'
import { PledgeRequest } from 'src/models/requests/pledge-request'
import { pledgeApiClient } from 'src/utilities/api-clients/pledge-api-client'

const FORM_NAME = 'pledge-support'

export const PledgeSupportPage = () => {
    const { setIsLoading } = useLoader()
    const { setAgency } =  useUserDispatch()
   
    const history = useHistory()
    const onSubmit = async (data: PledgeRequest) => {
        setIsLoading(true)
        setAgency(data.agency)
        const pledgeResponse = await pledgeApiClient.pledgeAssistance(data)
        history.push(DashboardRoute, { confirmationCode: pledgeResponse?.confirmationCode })
        setIsLoading(false)
    }
    const methods = useForm<PledgeRequest>()
    const { handleSubmit } = methods

    return (
        <FormProvider {...methods}>
            <h1>Pledge Support</h1>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <FormSectionTitle>Agency Information:</FormSectionTitle>
                    <AgencyForm formName={FORM_NAME} />
                </div>
                <div>
                    <FormSectionTitle>SCANA Customer Information:</FormSectionTitle>
                    <CustomerInformationForm formName={FORM_NAME} />
                </div>
                <div>
                    <FormSectionTitle>Pledge Amount:</FormSectionTitle>
                    <PledgeAmountForm formName={FORM_NAME} />
                </div>
                <div>
                    <FormSectionTitle>Information Request:</FormSectionTitle>
                    <InfoRequestForm formName={FORM_NAME} isRequired={false} />
                </div>
                <FormControlButtons formName={FORM_NAME}/>
            </StyledForm>
        </FormProvider>
    )
}
