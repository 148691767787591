import { Agency } from 'src/models/agency'
import { createAction, reducer } from 'src/utilities/reducer-utilities'

export interface UserState {
  readonly agency: Agency
}

export const userInitialState: UserState = {
  agency: {
    AgencyName: '',
    PhoneNumber: '',
    RepresentativeName: '',
    ResponseContact: '',
  }
}

const userActionTypes = {
  SET_AUTH: '[user] SET_AUTH',
  SET_AGENCY: '[user] SET_AGENCY',
}

export const userActions = {
  setAuth: createAction<string>(userActionTypes.SET_AUTH),
  setAgency: createAction<Agency>(userActionTypes.SET_AGENCY),
}

export const userReducer = reducer<UserState>(userInitialState)
  .add<Agency>(userActionTypes.SET_AGENCY, (state, agency) => {
    return { ...state, agency: agency }
  })
  .build()
