import { userStorageKey } from 'src/domain/authentication/hooks/use-user'
import { ApiResponse } from 'src/models/responses/api-response'
import { webPledgeError, WebPledgeError } from 'src/models/web-pledge-errors'

import { getApiUrl, getCacheBusterValue, getCdnUrl, getEnvironment } from '../environment-utilities'
import { clearFromStorage, getFromStorage, putInStorage } from '../storage-utilities'

export interface RequestConfig {
    readonly throwErrorObject?: boolean
    readonly hideLoadingMask?: boolean
    readonly ignoreErrors?: boolean
    readonly overrideNonUserPresentableErrors?: string
    readonly overrideAllErrors?: string
}

interface Configuration {
    readonly ApiUrl: string
    readonly CacheBuster: string
    readonly CdnUrl: string
    readonly Environment: string
}

declare global {
    interface Window {
        WebPledgeApiClient?: any
    }
}

const authTokenKey = 'auth-token'

class ApiClient {
    public apiBaseUrl: string | undefined
    public cacheBuster: string | undefined
    public cdnUrl: string | undefined
    public environment: string | undefined

    constructor() {
        this.loadConfiguration()
    }

    private configurationPromise: Promise<Configuration> | undefined

    public readonly loadConfiguration = async () => {
        if (!this.configurationPromise) {
            this.configurationPromise = new Promise(async resolve => {
                const versionNumber = 1
                const environment = getEnvironment()
                const storedResult = getFromStorage<Configuration & { readonly versionNumber: number }>('ui-configuration')
                if (storedResult && versionNumber === storedResult.versionNumber) {
                    resolve(storedResult)
                    return
                }

                const serverResult = {
                    ApiUrl: getApiUrl(environment),
                    CacheBuster: getCacheBusterValue(),
                    CdnUrl: getCdnUrl(environment),
                    Environment: environment,
                }

                    putInStorage({ ...serverResult, versionNumber }, 'ui-configuration')
                    resolve(serverResult)
                    return
            })
        }

        const configuration = await this.configurationPromise
        this.apiBaseUrl = `${configuration.ApiUrl}/api`
        this.cacheBuster = configuration.CacheBuster
        this.cdnUrl = configuration.CdnUrl
        this.environment = configuration.Environment
    }

    public readonly getAssetPath = (path: string) => `${apiClient.cdnUrl}assets/${path}?c=${apiClient.cacheBuster}`

    public readonly makeRequest = async (url: string, request?: RequestInit | null, config?: RequestConfig | null) => {
        config = config || {}
        request = request || {}
        let response

        const token = this.getToken()

        if (token && this.apiBaseUrl && url.includes(this.apiBaseUrl)) {
            request.headers = {
                ...request.headers,
                Authorization: `Bearer ${token}`,
            }
        }

        try {
            response = await fetch(url, request)
        } catch (e: any) {
            if (this.isConnectionError(e)) {
                this.throwError(true, 'Unable to connect to server', config)
            } else {
                this.throwError(false, e.exceptionMessage || e.message, config)
            }
        } 

        if (response && !response.ok) {
            if (response.status === 401) {
                this.logout()
            }

            const error = await this.getError(response)
            this.throwError(error.isMessageUserPresentable, error.message, config)
        }

        return response
    }

    private readonly isConnectionError = (e: Error): boolean => {
        return e && e.message === 'Failed to fetch'
    }

    private readonly throwError = (isMessageUserPresentable: boolean, message: string, config: RequestConfig) => {
        if (config.overrideAllErrors) {
            throw Error(config.overrideAllErrors)
        } else if (config.overrideNonUserPresentableErrors && !isMessageUserPresentable) {
            throw Error(config.overrideNonUserPresentableErrors || 'An error has occurred')
        } else {
            throw Error(message)
        }
    }

    public readonly get = async <T>(url: string, config?: RequestConfig | null) => {
        const response = await this.makeRequest(url, null, config)
        return response ? ((await response.json()) as T) : null
    }

    public readonly post = async <T>(url: string, body?: any, config?: RequestConfig | null) => {
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }

        const response = await this.makeRequest(url, request, config)
        return response && response.status !== 204 ? ((await response.json()) as T) : await null
    }

    public readonly login = async (username: string, password: string, config?: RequestConfig) => {
        const loginResponse = await this.post<ApiResponse<string>>(`${this.apiBaseUrl}/WebPledge/login`, { username, password }, config)
      
        if (!loginResponse || !loginResponse.data) {
            throw Error('Login Failed')
        }
        
        clearFromStorage(userStorageKey)
        
        this.setUserToken(loginResponse.data)

        const storedToken = this.getToken()

        if (!storedToken) {
            throw Error('Please enable cookies to continue')
        }
    }

    public readonly logout = async (config?: RequestConfig) => {
        try {
            await this.post<string>(`${this.apiBaseUrl}/logout`, { token: this.getToken() }, config)
        } catch (e: any) {
            console.error(`unable to invalidate token: ${e.message}`)
        } finally {
            clearFromStorage(authTokenKey, true)
            clearFromStorage(userStorageKey)
        }
    }

    public readonly hasToken = () => {
        return !!this.getToken()
    }

    private readonly getToken = () => {
        return getFromStorage(authTokenKey, true)
    }

    public readonly setUserToken = (token: string) => putInStorage(token, authTokenKey, true)

    public readonly isAppOffline = async (config: RequestConfig) => {
        return await this.get<boolean>('/data-api/MaintenanceMode/GetMaintenanceModeValue', config)
    }

    private readonly getError = async (response: Response): Promise<WebPledgeError> => {
        switch (response.status) {
            case 400:
                return this.get400Error(response)
            default:
                return this.get500Error(response)
        }
    }

    private readonly get400Error = async (response: Response): Promise<WebPledgeError> => {
        return (await response.json()) as WebPledgeError
    }

    private readonly get500Error = async (response: Response): Promise<WebPledgeError> => {
        const json = (await response.json()) as WebPledgeError & { readonly exceptionMessage?: string }

        return webPledgeError(
            json ? json.exceptionMessage || json.message || '' : response.statusText,
            json && json.isMessageUserPresentable,
            json && json.errorCode,
        )
    }
}

if (!window.WebPledgeApiClient) {
    window.WebPledgeApiClient = new ApiClient()
}

export const apiClient: ApiClient = window.WebPledgeApiClient