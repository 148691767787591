import { BrowserRouter as Router } from 'react-router-dom'
import styled, { createGlobalStyle, ThemeProps, ThemeProvider } from 'styled-components'
import { normalize } from 'styled-normalize'

import { Footer } from 'src/components/footer'
import { Header } from 'src/components/header'
import { Loader } from 'src/components/loader'
import { defaultTheme, WebPledgeTheme } from 'src/components/theme-wrapper'

import { UserProvider } from './domain/authentication/hooks/use-user'
import { LoaderProvider } from './domain/hooks/loader-context'
import { Routing } from './domain/routes/routing'

const BodyWrapper = styled.div`
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
    padding: 0 10px;
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0 2%;
    }
`
const AppStyle = createGlobalStyle`
  ${normalize}
    html {
        background-color: ${({ theme }: ThemeProps<WebPledgeTheme>): string => theme.footerBackgroundColor};
    }
    body{
        background-color: ${({ theme }: ThemeProps<WebPledgeTheme>): string => theme.bodyBackgroundColor};
    }
  * {
      font-size: 1em;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
`

function App() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <AppStyle />
            <LoaderProvider>
                <UserProvider>
                    <Router forceRefresh={true}>
                        <Header />
                        <BodyWrapper>
                            <Routing />
                        </BodyWrapper>
                        <Footer />
                        <Loader />
                    </Router>
                </UserProvider>
            </LoaderProvider>
        </ThemeProvider>
    )
}

export default App
