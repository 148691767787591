import { useHistory } from 'react-router-dom'
import { HtmlType } from 'src/constants/html-types'
import { DashboardRoute } from 'src/domain/routes'
import styled from 'styled-components'

import { Button } from '@igs/react-styled-components'

const ButtonContainer = styled.div`
    display:grid;
    justify-content:right;
    grid-template-columns: max-content max-content;
    grid-column-gap: 36px;

`

export const FormControlButtons = ({ formName }: Props) => {
    const history = useHistory()
    return (
        <ButtonContainer>
            <Button dataTestId={`${formName}-submit`} type={HtmlType.Submit}>
                Submit
            </Button>

            <Button
                dataTestId={`${formName}-cancel`}
                onClick={() => {
                    history.push(DashboardRoute)
                }}
            >
                Cancel
            </Button>
        </ButtonContainer>
    )
}

interface Props {
    readonly formName: string
}
