import { useHistory, useLocation } from 'react-router-dom'
import { Grid1fr1fr } from 'src/components/grid-layout'
import { FormSelectionHelpCard } from 'src/domain/form-selection/form-selection-help-card'
import { PledgeRoute, RequestInfoRoute } from 'src/domain/routes'
import styled from 'styled-components'

import { Spacing } from '@igs/react'
import { Body1, Button, Card, Headline6 } from '@igs/react-styled-components'
import { ConfirmationCard } from 'src/components/confirmation-card'

const StyledButton = styled(Button)`
    width: 100%;
`

const SelectionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${Spacing.large};
`

export const DashboardPage = () => {
    const history = useHistory()
    const { state } = useLocation<DashboardStateProps>()
    const { confirmationCode, informationRequestSubmission } = state || {}
    return (
        <Grid1fr1fr>
            {confirmationCode && (
                <ConfirmationCard>
                    <Headline6>Successfully submitted pledge, confirmation #:{confirmationCode}</Headline6>
                </ConfirmationCard>
            )}
            {informationRequestSubmission && (
                <ConfirmationCard>
                    <Headline6>Successfully submitted information request</Headline6>
                </ConfirmationCard>
            )}
            <SelectionContainer>
                <Card>
                    <Body1>
                        Pledge support to assist customers. This option requires customer account information to submit a pledge. If you don't have the customer
                        account information, please select the Request Info link below.
                    </Body1>
                    <StyledButton
                        dataTestId={'pledge-support-button'}
                        onClick={() => {
                            history.push(PledgeRoute)
                        }}
                    >
                        Pledge Support
                    </StyledButton>
                </Card>
                <Card>
                    <Body1>
                        Request account and historic information for the customers you would like to assist. If you already have the account details, and want
                        to submit a pledge, please select the Pledge Support link above.
                    </Body1>
                    <StyledButton
                        dataTestId={'request-info-button'}
                        onClick={() => {
                            history.push(RequestInfoRoute)
                        }}
                    >
                        Request Info
                    </StyledButton>
                </Card>
            </SelectionContainer>
            <FormSelectionHelpCard />
        </Grid1fr1fr>
    )
}

interface DashboardStateProps {
    readonly confirmationCode: string | undefined
    readonly informationRequestSubmission: boolean | undefined
}
