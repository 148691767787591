import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { Input, Radio } from 'src/components/web-framework-inputs'
import { CustomerType } from 'src/constants/customer-type'
import { HtmlType } from 'src/constants/html-types'
import { StateCode } from 'src/constants/states'
import { Customer } from 'src/models/customer'
import { onChangeAlphaNumeric, validAccountNumber } from 'src/utilities/input-validation-utilities'

import { ErrorMessage, ErrorWrapper } from '../errors'
import { Grid1fr1fr } from '../grid-layout'

export const CustomerInformationForm = ({ formName }: Props) => {
    const methods = useFormContext<CustomerInformationFields>()
    const {
        register,
        formState: { errors },
    } = methods

    return (
        <Grid1fr1fr>
            <Input
                {...register('customer.Name', { required: 'Required' })}
                dataTestId={`${formName}-customer-name`}
                label="Account Holder Name"
                error={errors.customer?.Name?.message}
                showRequired={true}
                hideHelperText={!errors.customer?.Name}
                type={HtmlType.Text}
            />
            <Input
                {...register('customer.BillingAccountNumber', { required: 'Required', pattern: validAccountNumber })}
                dataTestId={`${formName}-account-number`}
                label="Billing Account Number"
                error={errors.customer?.BillingAccountNumber?.message}
                showRequired={true}
                hideHelperText={!errors.customer?.BillingAccountNumber}
                type={HtmlType.Text}
                maxLength={50}
                onKeyPress={onChangeAlphaNumeric}
            />
            <ErrorWrapper isValid={!errors.customer?.CustomerType}>
                <Grid1fr1fr>
                    <Radio
                        {...register('customer.CustomerType', { required: 'Required' })}
                        label="SCANA Regulated"
                        value={CustomerType.ScanaRegulated}
                        dataTestId={`${formName}-scana-regulated-customer`}
                    />
                    <Radio
                        {...register('customer.CustomerType', { required: 'Required' })}
                        label="SCANA Energy"
                        value={CustomerType.ScanaEnergy}
                        dataTestId={`${formName}-scana-energy-customer`}
                    />
                    {errors.customer?.CustomerType && <ErrorMessage>{errors.customer?.CustomerType.message}</ErrorMessage>}
                </Grid1fr1fr>
            </ErrorWrapper>
            <span />
            <Input
                {...register('customer.Address1', { required: 'Required' })}
                dataTestId={`${formName}-address1`}
                label="Service Address"
                error={errors.customer?.Address1?.message}
                showRequired={true}
                hideHelperText={!errors.customer?.Address1}
                type={HtmlType.Text}
            />
            <span />
            <Input
                {...register('customer.Address2')}
                dataTestId={`${formName}-address2`}
                label="Service Address Line 2"
                error={errors.customer?.Address2?.message}
                showRequired={false}
                hideHelperText={!errors.customer?.Address2}
                type={HtmlType.Text}
            />
            <span />
            <Input
                {...register('customer.City', { required: 'Required' })}
                dataTestId={`${formName}-city`}
                label="City"
                error={errors.customer?.City?.message}
                showRequired={true}
                hideHelperText={!errors.customer?.City}
                type={HtmlType.Text}
            />
            <Grid1fr1fr>
                <Input
                    {...register('customer.State', { required: 'Required' })}
                    readOnly={true}
                    dataTestId={`${formName}-state`}
                    label="State"
                    error={errors.customer?.State?.message}
                    hideHelperText={!errors.customer?.State}
                    defaultValue={StateCode.GA}
                    value={StateCode.GA}
                />
                <Input
                    {...register('customer.zipCode', { required: 'Required', maxLength: { value: 5, message: 'Zip Code must be 5 digits' } })}
                    dataTestId={`${formName}-zip-code`}
                    label="Zip Code"
                    error={errors.customer?.zipCode?.message}
                    showRequired={true}
                    hideHelperText={!errors.customer?.zipCode}
                    type={HtmlType.Text}
                />
            </Grid1fr1fr>
        </Grid1fr1fr>
    )
}

interface CustomerInformationFields {
    customer: Customer
}

interface Props {
    readonly formName: string
}
