import styled from 'styled-components'

export const StyledForm = styled.form`
    display: grid;
    grid-row-gap: 24px;
    >div{
        border-top: 2px solid #037AC9;
        padding: 26px 16px 0 16px;
    }
    >div:last-child, >div:first-child{
        border-top: none;
        padding-top: 0;
    }
`

export const FormSectionTitle = styled.h2`
    margin-bottom:12px;
    margin-top:0;
    padding-top:0;
    line-height: 1rem;
    font-size:1.1rem;
    margin-left:-10px;
`