import { LoadingSpinner, Modal } from '@igs/react-styled-components'
import { useLoader } from 'src/domain/hooks/loader-context'

export const Loader = () => {
    const { isLoading } = useLoader()
    return (
        <>
            {isLoading && (
                <Modal size={'fit'} dataTestId={'loader-modal'} isOpen={isLoading}>
                    <LoadingSpinner dataTestId={'loading-spinner'} />
                </Modal>
            )}
            {!isLoading && <></>}
        </>
    )
}
