import * as React from 'react'
import { Elevation, Spacing } from '@igs/react'
import { Headline3 } from '@igs/react-styled-components'
import styled, { ThemeProps } from 'styled-components'

import { default as ScanaLogo } from 'src/assets/scana-energy-logo.svg'
import { LogoutButton } from 'src/components/logout-button'
import { apiClient } from 'src/utilities/api-clients/api-client'
import { WebPledgeTheme } from './theme-wrapper'
import { Breakpoint } from 'src/domain/styles/breakpoints'

const HeaderBar = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: ${Spacing.huge};
    justify-items: end;
    padding: ${Spacing.medium};
    margin-bottom: ${Spacing.huge};
    border-bottom: ${({ theme }: ThemeProps<WebPledgeTheme>): string => theme.border};

    color: ${({ theme }: ThemeProps<WebPledgeTheme>): string => theme.surface.textOn};
    background: ${({ theme }: ThemeProps<WebPledgeTheme>): string => theme.surface.color};
    box-sizing: border-box;
    box-shadow: ${Elevation.low};
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr 1fr;
    }
`

const StyledHeadline3 = styled(Headline3)`
    justify-self: center;
    font: ${Spacing.huge};
    margin: 0px;
    white-space: nowrap;
    @media (max-width: ${Breakpoint.Tablet}) {
        display: none;
    }
`

const Logo = styled.img`
    height: ${Spacing.huge};
    justify-self: start;
`

export const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(apiClient.hasToken())

    React.useEffect(() => {
        setIsLoggedIn(apiClient.hasToken())
    }, [setIsLoggedIn])
    return (
        <HeaderBar>
            <Logo src={ScanaLogo} alt="SCANA Energy" />
            <StyledHeadline3>Web Pledge</StyledHeadline3>
            {isLoggedIn && <LogoutButton />}
            {!isLoggedIn && <span />}
        </HeaderBar>
    )
}
